import { lazy } from "react";
import { createRoutesFromElements, createBrowserRouter, Route } from "react-router-dom";

// import Root from "./routes/root";
const Root = lazy(() => import("./routes/root"));

const ErrorPage = lazy(() => import("./ErrorPage"));
const Home = lazy(() => import("./Home"));
const HomePage = lazy(() => import("./HomePage"));
const Explore = lazy(() => import("./Explore"));
const Login = lazy(() => import("./Login"));
const SignUp = lazy(() => import("./SignUp"));
const ConfirmSignUp = lazy(() => import("./ConfirmSignUp"));
const Admin = lazy(() => import("./routes/admin"));
const AdminCategories = lazy(() => import("./admin/Categories"));
const CategoriesWrapper = lazy(() => import("./admin/CategoriesWrapper"));
const AdminCategory = lazy(() => import("./admin/Category"));
const AdminUsers = lazy(() => import("./admin/Users"));
const DataItemDetail = lazy(() => import("./admin/DataItemDetail"));
const NavigateData = lazy(() => import("./NavigateData"));
// const ViewSpotfire = lazy(() => import("./ViewSpotfire"));
// const ViewDataItem = lazy(() => import("./ViewDataItem"));
// const Data = lazy(() => import("./Data"));
const Faq = lazy(() => import("./Faq"));
const ExplorePage = lazy(() => import("./ExplorePage"));

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
      <Route errorElement={<ErrorPage />}>
        <Route element={<Home />}>
          <Route index element={<HomePage />} />
          <Route path="login" element={<Login />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="faq" element={<Faq />} />
          <Route path="explore" element={<Explore />} />
          <Route path="confirm-sign-up" element={<ConfirmSignUp />} />
          <Route path="categories/:id" element={<NavigateData />} />
          {/* <Route path="data-item/:id" element={<ViewSpotfire />} /> */}
          <Route path="explore/:name" element={<ExplorePage />} />
        </Route>
        {/* <Route path="data" element={<Data />}>
          <Route path="explore/:id" element={<ViewSpotfire />} />
          <Route path="item/:id" element={<ViewDataItem />} />
        </Route> */}
        <Route path="admin" element={<Admin />}>
          <Route element={<CategoriesWrapper />}>
            <Route path="categories/:id/data-items/:dataItemId" element={<DataItemDetail />} />
            <Route path="categories/:id" element={<AdminCategory />} />
            <Route path="categories" index element={<AdminCategories />} />
          </Route>
          <Route path="users" element={<AdminUsers />} />
        </Route>
      </Route>
    </Route>
  )
);
